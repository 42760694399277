// Styles are repeated in JS so that they can be passed to iframe over postMessage
const cssVariables = {
    '--coretm-darkgrey': '#31312F',
    '--coretm-darkgreen': '#4D7E70',
    '--coretm-lightgreen': 'hsl(95, 28%, 90%)',
    '--coretm-eggshellyellow': '#EEDBA6',
    '--coretm-rustred': '#BA502E'
}

export default cssVariables
